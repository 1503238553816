import React from "react";
import PlatformAnimation from "assets/animation/platform.mp4";
import TextLogo from "assets/svg/textLogo.svg";
import "./style.scss";
import { Link } from "react-router-dom";

export default function Platform() {
  return (
    <section className="grantai-platform-outter-container" id="platform">
      <div className="grantai-platform-container">
        <div className="video-wrapper">
          <video muted autoPlay={true} loop={true} playsInline>
            <source src={PlatformAnimation} type="video/mp4" />
          </video>
        </div>
        <div className="platform-description">
          <h2 className="title">
            <label>
              The <img src={TextLogo} alt="text logo" />
            </label>{" "}
            <strong>Platform</strong>
          </h2>
          <h4 className="description">
            Unlock global funding opportunities and craft winning proposals with our cutting-edge platform. Our AI assistant analyzes, refines, and enhances your applications in real-time, while integrated research applications ensure your proposals are scientifically robust. Elevate your grant writing, expand your funding options, and increase your chances of success on a global scale with <span>GrantAi</span>.
          </h4>
          <div className="action-button-container">
            <Link to="/wishlist" className="action-button">
              Get Started
            </Link>
            <Link className="action-button" to="/howitworks">
              How It Works
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
