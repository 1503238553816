import React from "react";
import remarkGfm from "remark-gfm";
import ReactMarkdown from 'react-markdown';
import './style.scss';

interface FormatterProps {
  answer: string;
}

const Formatter: React.FC<FormatterProps> = ({answer}) => {

  const components = {
    h3: ({ node, ...props }) => <h3 className="h3-formatter" {...props} />,
      p: ({ node, ...props }) => <div className="p-formatter" {...props} />,
      li: ({ node, ...props }) => <li className="li-formatter" {...props} />,
      ol: ({ node, ...props }) => <ol className="ol-formatter" {...props} />,
  };

  return (
    <div className="grantai-formattedText-container">
      <ReactMarkdown
        children={answer}
        remarkPlugins={[remarkGfm]}
        components={components}
      />
    </div>
  );
};

export default Formatter;