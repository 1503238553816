import Contact from "Components/Contact";
import React from "react";

export default function ContactPage() {
  return (
    <>
      <Contact />
    </>
  );
}
