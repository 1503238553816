import axios from 'axios';
import {Endpoints} from "../../../config/consts";

export async function getSharedChatById(id: string) {
  try {
    const url = `${Endpoints.publicSharedChat}/${id}`
    const response = await axios.get(url);
      return response.data;
  }catch (error) {
    console.log(error);
  }
}