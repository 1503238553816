import React from "react";
import Global from 'assets/img/global.png'
import "./style.scss";

export default function GlobalOpportunities() {
  return (
    <div className="global-opportunities-outter-container">
      <div className="global-opportunities">
        <div className="global-opportunity-background" style={{ backgroundImage: `url(${Global})` }} />
        <div className="description-container">
          <div>
            <h2 className="title"><strong>Global</strong> Grant Opportunities</h2>
          </div>
          <h4 className="description">
            Access worldwide research funding through our comprehensive platform. We aggregate grants from diverse international sources, connecting you to opportunities across the globe. Expand your funding opportunities, foster international collaborations, and amplify your research impact on a global scale.
          </h4>
        </div>
      </div>
    </div>
  );
}
