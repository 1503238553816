import React from "react";
import ValidationLogo from 'assets/img/validation.png';
import "./style.scss";

export default function Validation() {
  return (
    <div className="validation-app-outter-container">
      <div className="validation-app">
        <div className="validation-background" style={{ backgroundImage: `url(${ValidationLogo})` }} />
        <div className="description-container">
          <div>
            <h2 className="title">Validation through our <strong>App Integration</strong></h2>
          </div>
          <h4 className="description">
            Access worldwide research funding through our comprehensive platform. We aggregate grants from diverse international sources, connecting you to opportunities across the globe. Expand your funding opportunities, foster international collaborations, and amplify your research impact on a global scale.
          </h4>
        </div>
      </div>
    </div>
  );
}
