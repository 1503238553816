import React, { useEffect } from "react";
import Howitworks from "Components/Howitworks";

export default function HowitworksPage() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top-left corner of the page
  }, []);
  return (
    <>
      <Howitworks />
    </>
  );
}
