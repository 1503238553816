import React, { useState } from "react";
import { useNavigate } from "react-router";
import "./style.scss";

import Step1 from 'assets/img/step1.png';
import Step2 from "assets/img/step2.png";
import Step3 from "assets/img/step3.png";
import Step4 from "assets/img/step4.png";
import Step5 from "assets/img/step5.png";
import Step6 from "assets/img/step6.png";
import Step7 from "assets/img/step7.png";

import CircleCardContent from "assets/img/final-circle-content.png";

import Image10 from "assets/img/image10.png";
import Image11 from "assets/img/image11.png";
import Image12 from "assets/img/image12.png";
import SecondImage10 from "assets/img/image10-2.png";
import SecondImage12 from "assets/img/image12-2.png";
import Send from "assets/svg/send.svg";
import Search from "assets/svg/search.svg";
import TextLogo from "assets/svg/textLogo.svg";

export default function Howitworks() {
  const navigate = useNavigate();

  const searchGrant = () => {
    navigate(`/grantai?tab=${0}`);
  };
  return (
    <div className="howitworks-container">
      <h2 className="howitworks-title">
        <label>
          How <img src={TextLogo} alt="grantai text logo" />
        </label>
        <strong>Works</strong>
      </h2>
      <main className="howitworks-content">
        <section className="howitworks-card" id="step-1">
          <article className="howitworks-card-content">
            <div className="step-title">
              <h3 className="step-label">
                <strong>
                  Step <span>1</span>
                </strong>
              </h3>
              <h2>Search for Grants</h2>
            </div>
            <h4>
              Access worldwide research funding through our comprehensive platform. We will find you Grants that best match your project or research needs from diverse international sources, connecting you to opportunities across the globe that align with your objectives.
            </h4>
          </article>
          <div className="howitworks-first-cover-img">
            <img alt="card" src={Step1} />
          </div>
        </section>
        <section className="howitworks-card" id="step-2">
          <div className="howitworks-first-cover-img">
            <img alt="Card1" src={Step2} />
          </div>
          <article className="howitworks-card-content">
            <div className="step-title">
              <h3 className="step-label">
                <strong>
                  Step <span>2</span>
                </strong>
              </h3>
              <h2>Review your Grant</h2>
            </div>
            <h4>
              Carefully read the grant guidelines to ensure your project fits
              within the scope. Check eligibility criteria such as organization
              type, project focus, and funding requirements to confirm that your
              proposal meets all necessary conditions.
            </h4>
          </article>
        </section>
        <section className="howitworks-card" id="step-3">
          <article className="howitworks-card-content">
            <div className="step-title">
              <h3 className="step-label">
                <strong>
                  Step <span>3</span>
                </strong>
              </h3>
              <h2>Craft your proposal</h2>
            </div>
            <h4>
              Elevate your grant writing with our AI verification system. This feature analyzes proposals in real-time, offering suggestions to enhance clarity and align with funders' requirements. Our Grant editor and AI assistant helps craft compelling applications, streamlining the process and boosting your chances of securing funding.
            </h4>
          </article>
          <div className="howitworks-second-cover-img">
            <img alt="Card1" src={Step3} />
          </div>
        </section>
        <section className="howitworks-card" id="step-4">
          <div className="howitworks-first-cover-img blur-effect-container">
            <img alt="Card1" src={Step4} />
          </div>
          <article className="howitworks-card-content">
            <div className="step-title">
              <h3 className="step-label">
                <strong>
                  Step <span>4</span>
                </strong>
              </h3>
              <h2>Enhance your proposal</h2>
            </div>
            <h4>
              Strengthen your proposals with our integrated research applications, tailored to your research needs. Seamlessly incorporate real time data analysis and simulations, into your proposal. These specialized applications help generate robust, evidence-based content, ensuring your proposals are scientifically sound and compelling to funders increasing your chances of getting funding.
            </h4>
          </article>
        </section>
        <section className="howitworks-card" id="step-5">
          <article className="howitworks-card-content">
            <div className="step-title">
              <h3 className="step-label">
                <strong>
                  Step <span>5</span>
                </strong>
              </h3>
              <h2>Verify your proposal</h2>
            </div>
            <h4>
              Our GrantAI agent will verify your proposal and provide helpful suggestions related to organizational guidelines, research papers, and much more.
            </h4>
          </article>
          <div className="howitworks-second-cover-img">
            <img alt="Card1" src={Step5} />
          </div>
        </section>
        <section className="howitworks-card" id="step-6">
          <div className="howitworks-first-cover-img">
            <img alt="Card1" src={Step6} />
          </div>
          <article className="howitworks-card-content">
            <div className="step-title">
              <h3 className="step-label">
                <strong>
                  Step <span>6</span>
                </strong>
              </h3>
              <h2>Export your proposal</h2>
            </div>
            <h4>
              Once your proposal is ready it is time to export and submit it to the funding source. GrantAi will export a ready-to-submit document that you can submit to the organisation of your choosing.
            </h4>
          </article>
        </section>
        {/* <section className="howitworks-card" id="step-7">
          <article className="howitworks-card-content">
            <div className="step-title">
              <h3 className="step-label">
                <strong>
                  Step <span>7</span>
                </strong>
              </h3>
              <h2>Secure the funding!</h2>
            </div>
            <h4>
              Once your proposal is submitted it is time to wait. Bear in mind that Grants are often highly competitive, acceptance rates for proposals are usually low. It is very typical to not be successful on the first try and to have to apply for the same grant multiple times. But with GrantAi you will increase your chances of securing the funding!
            </h4>
          </article>
          <div className="howitworks-second-cover-img">
            <img alt="Card1" src={Step7} />
          </div>
        </section> */}
      </main>
    </div>
  );
}
