import GrantAnimation from "Components/HomePage/GrantAnimation";
import Hero from "Components/HomePage/Hero";
import Partners from "Components/HomePage/Partners";
import Platform from "Components/HomePage/Platform";
import GlobalOpportunities from "Components/HomePage/GlobalOpportunities";
import WhatWeOffer from "Components/HomePage/WhatWeOffer";
import React, { useEffect } from "react";
import GrantProposals from "Components/HomePage/GrantProposals";
import Validation from "Components/HomePage/Validation";

export default function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top-left corner of the page
  }, []);
  return (
    <>
      <Hero />
      <Platform />
      <GlobalOpportunities />
      <GrantProposals />
      <Validation />
      <WhatWeOffer />
      <GrantAnimation />
      <Partners />
    </>
  );
}
