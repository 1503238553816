import React from "react";
import Proposal from 'assets/img/proposal.png'
import "./style.scss";

export default function GrantProposals() {
  return (
    <div className="grant-proposal-outter-container">
      <div className="grant-proposal">
        <div className="description-container">
          <div>
            <h2 className="title"><strong>AI-Powered</strong></h2>
            <h2 className="title">Grant Proposals</h2>
          </div>
          <h4 className="description">
            Elevate your grant writing with our AI verification system. This feature analyzes proposals in real-time, offering suggestions to enhance clarity and align with funders' requirements. Our AI assistant helps craft compelling applications, streamlining the process and boosting your chances of securing funding. Powered by AI technologies, including Gemini Ultra, ChatGPT and our own proprietary Large Action Model algorithms, to match research projects with the perfect funding opportunities efficiently and accurately.
          </h4>
        </div>
        <div className="grant-proposal-background" style={{ backgroundImage: `url(${Proposal})` }} />
      </div>
    </div>
  );
}
