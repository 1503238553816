import { ReactElement, useEffect, useState } from "react";
import "./style.scss";
import Logo from "../../assets/svg/grantAi-logo.svg";
import { useNavigate, useParams } from "react-router-dom";
import { getSharedChatById } from "./services/sharedChat.service";
import { ChatShared } from "./types/types";
import Formatter from "../../utils/formatter";

export default function SharedChat(): ReactElement {
  const { id } = useParams();
  const navigate = useNavigate();
  const [chat, setChat] = useState<ChatShared>(null);

  useEffect(() => {
    const getSharedChat = async () => {
      const response = await getSharedChatById(id);
      if (response) {
        setChat(response);
      }
    };
    getSharedChat();
  }, []);

  const formatTimestamp = (timestamp: string): string => {
    const dateObj = new Date(timestamp);
    const hours = ("0" + dateObj.getUTCHours()).slice(-2);
    const minutes = ("0" + dateObj.getUTCMinutes()).slice(-2);
    return `${hours} : ${minutes} · ${dateObj.toLocaleString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    })}`;
  };

  const navigateToLogin = () => {
    navigate("/wishlist", { replace: true });
  };

  return (
    <div className="chat-shared-container">
      <div className="chat-shared-title">
        <label>AiChat:</label>
        <label>{chat?.title}</label>
      </div>
      <div className="chat-section">
        <div>
          {chat &&
            chat.parent_items.map((value) => (
              <div className="chat-container" key={value.id}>
                <div className="chat-container-title">
                  <label>{value.question}</label>
                  <label>{formatTimestamp(value.created_at)}</label>
                </div>
                <div className="chat-container-content">
                  <div className="chat-content-logo">
                    <img src={Logo} alt="logo" />
                    <label>GrantAI</label>
                  </div>
                  <Formatter answer={value.answer} />
                </div>
                <label className="separator-line"></label>
              </div>
            ))}
        </div>
      </div>
      <button className="chat-shared-login-button" onClick={navigateToLogin}>
        Log in to continue
      </button>
    </div>
  );
}
